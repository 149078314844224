import * as React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { PARTNER_BRAND_PAGE } from '@/src/modules/pg-plus/constants'

type TLogoProps = {
    isCorporate: boolean
    minWidth?: string | number
    partner?: string
}

const LogoLink = ({ isCorporate, minWidth = '170px', partner }: TLogoProps) => {
    const imageCss = {
        height: 'auto',
        minWidth: minWidth,
        maxWidth: '100%',
        display: 'block',
    }
    const consumerHomeUrl: string = partner ? PARTNER_BRAND_PAGE : '/'

    if (isCorporate) {
        return (
            <NextLink href={'/corporate'}>
                <Image
                    src="/logo-horizontal-corporate.svg"
                    alt="PerfectGift Corporate logo"
                    height={93}
                    width={292}
                    style={imageCss}
                    priority
                />
            </NextLink>
        )
    }

    return (
        <NextLink href={consumerHomeUrl}>
            <Image
                src="/logo-horizontal.svg"
                alt="PerfectGift logo"
                height={73}
                width={300}
                style={imageCss}
                priority
            />
        </NextLink>
    )
}

export default LogoLink
