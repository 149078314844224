import {
    APP_TYPE,
    PARTNER_DATA_COOKIE,
    UTM_CAMPAIGN,
    UTM_ID,
    UTM_MEDIUM,
    UTM_SOURCE,
} from '@/src/common/constants'
import { GA_TRACKING_ID, pageview } from '@/src/common/utilities/gtag'
import { AuthProvider } from '@/src/modules/auth/context/AuthProvider'
import { globalCss } from '@/src/stitches.config'
import { Builder, builder } from '@builder.io/react'
import '@fontsource/shadows-into-light/400.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // import Font Awesome CSS
import * as FullStory from '@fullstory/browser'
import { SpeedInsights } from '@vercel/speed-insights/next'
import get from 'lodash/get'
import type { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { Router } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import builderConfig from '../config/builder'
import { DATADOME_JS, DATADOME_TAGS } from '@/datadome/constants'
import GlobalLayout from '../src/common/components/elements/Layout/GlobalLayout'
import PrivateLabelPartnerLayout from '../src/common/components/elements/Layout/PrivateLabelPartnerLayout'
import PhoneNumberReplacer from '../src/common/components/marketing/text/PhoneNumbers/PhoneNumberReplacer'
import '../styles/fonts.css'
import '../styles/globals.css'
import { RISKIFIED_SESSION_ID } from '@/config/cookieNames'
import { useIdleTimer } from 'react-idle-timer'
import {
    handleAdaCampaign,
    isExcludedPage,
    isGenAIPage,
} from '@/src/modules/chat-bot/utilities'

const { publicRuntimeConfig } = getConfig()

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// Initialize builder with your apiKey
builder.init(builderConfig.apiKey)
Builder.isStatic = true
// From Builder example - Heavy handed disable fonts for perf
builder.allowCustomFonts = false

const globalStyles = globalCss({
    body: { color: '$dark' },
    'h1, h2, h3, h4, h5, h6, h7': { color: '$darkest' },
    h1: {
        fontSize: '$8',
        '@md': {
            fontSize: '$9',
        },
    },
    h2: {
        fontSize: '$7',
        '@md': {
            fontSize: '$8',
        },
    },
    h3: {
        fontSize: '$6',
        '@md': {
            fontSize: '$7',
        },
    },
    h4: {
        fontSize: '$5',
        '@md': {
            fontSize: '$6',
        },
    },
    h5: {
        fontSize: '$4',
        '@md': {
            fontSize: '$5',
        },
    },
    h6: {
        fontSize: '$3',
        '@md': {
            fontSize: '$4',
        },
    },
    h7: {
        fontSize: '$2',
        '@md': {
            fontSize: '$3',
        },
    },
    p: {
        color: '$dark',
    },
    '.builder-text a': {
        textDecoration: 'underline',
        color: '$primary',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    '.builder-text ul': {
        marginBottom: '1rem',
    },
    '.pgc-table': {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 15px',
        '& thead': {
            textAlign: 'left',
        },
    },
})

function isCorporatePath(router: Router): boolean {
    const path = router.asPath.split('/')[1]
    return Boolean(
        !router.isFallback && path.includes('corporate')
        // And/Or we could use: Array(pageProps.content?.data?.pagetypeTag).includes('corporate').
        // And/Or we could use: pageProps.content?.data.themeId) === 'whatever is set within builder...'
    )
}

export default function MyApp(props: AppProps) {
    globalStyles()
    const { Component, pageProps, router } = props

    const pageTitle = pageProps?.content?.data?.title?.includes(
        'PerfectGift.com'
    )
        ? pageProps?.content?.data?.title
        : (pageProps?.content?.data?.title ?? '') + ' | PerfectGift.com'

    const noIndexNoFollow =
        pageProps?.content?.data?.meta?.noIndexNoFollow ?? false
    const pageDescription = pageProps?.content?.data?.description ?? ''
    const canonicalRef = pageProps?.content?.data?.canonicalRef
    const isCorporate = isCorporatePath(router)
    const disableExternalScripts: boolean = Boolean(
        parseInt(String(publicRuntimeConfig.axiosMockXhr))
    )
    const disableGoogleAnalytics =
        disableExternalScripts || Boolean(pageProps?.disableGoogleAnalytics)
    const enableDatadome = !disableExternalScripts
    const showPrivateLabelPartnerLayout =
        pageProps?.showPrivateLabelPartnerLayout ?? false

    const [queryClient] = React.useState(() => new QueryClient())

    useEffect(() => {
        const isPageToNotInit: boolean = [
            '/',
            '/check-balance',
            '/activate',
        ].some((route) => route === router.asPath)

        if (!isPageToNotInit && publicRuntimeConfig.fullstoryOrgId) {
            try {
                // checks to see if there is an active FullStory session
                const sessionUrl = !!FullStory?.getCurrentSessionURL()
                console.log(
                    'FullStory has already been initialized',
                    sessionUrl
                )
            } catch {
                // if there isn't, init FullStory
                console.log('Initializing FullStory...')
                FullStory.init({
                    orgId: publicRuntimeConfig.fullstoryOrgId,
                    devMode: process.env.NODE_ENV !== 'production',
                })
            }
        }
    }, [router.asPath])

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            pageview(url)
            if (window.RISKX) {
                // Notify Riskified beacon about the page change
                window.RISKX.go(url)
            }
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events])

    const urlPath = router.asPath.split('?')[0]
    const [cookies, setCookie, removeCookie] = useCookies([
        UTM_SOURCE,
        UTM_MEDIUM,
        UTM_CAMPAIGN,
        UTM_ID,
        PARTNER_DATA_COOKIE,
        RISKIFIED_SESSION_ID,
    ])

    // if user exists the partner flow, remove the stored cookie
    useEffect(() => {
        if (!!cookies?.PARTNER_DATA && !urlPath?.includes('/partner/')) {
            removeCookie('PARTNER_DATA')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies?.PARTNER_DATA, urlPath])

    useEffect(() => {
        const urlParams = new URLSearchParams(window?.location?.search)
        const utmSource = urlParams?.get(UTM_SOURCE)
        const utmMedium = urlParams?.get(UTM_MEDIUM)
        const utmCampaign = urlParams?.get(UTM_CAMPAIGN)
        const utmId = urlParams?.get(UTM_ID)
        //when ios launched we'll be setting an appType param in the launch url
        // that will tell it to hide the social login buttons
        const appType = urlParams?.get(APP_TYPE)
        const utm = [
            { name: UTM_SOURCE, value: utmSource },
            { name: UTM_MEDIUM, value: utmMedium },
            {
                name: UTM_CAMPAIGN,
                value: utmCampaign,
            },
            { name: UTM_ID, value: utmId },
        ]

        utm.forEach((item) => {
            const { name, value } = item
            if (!!value && value !== get(cookies, name)) {
                setCookie(name, value)
            }
        })
        //if the url param appType exists then set it in the cookie
        //we'll use this later in the login form
        if (appType) {
            setCookie(APP_TYPE, appType)
        }
    }, [cookies, setCookie, router.asPath])

    // handle displaying ADA chatbot on pages
    const path = router.asPath?.split('?')[0]
    const adaDataHandle = isGenAIPage(path)
        ? 'demo-perfectgift-gr'
        : 'perfectgift'

    useEffect(() => {
        const isValidPageForAda: boolean = !isExcludedPage(path)
        const adaScript = document.getElementById('__ada')
        const adaEntry = document.getElementById('ada-entry')

        // if invalid ada page and script exists, remove it
        if (!isValidPageForAda && !!adaScript) {
            document.body.removeChild(adaScript)

            // if ada entry is present, hide it
            if (adaEntry) {
                adaEntry.style.display = 'none'
            }

            console.log('chat disabled')

            return
        }

        // if valid page and no script, add it
        if (isValidPageForAda && !adaScript) {
            const script = document.createElement('script')
            script.src = 'https://static.ada.support/embed2.js'
            script.id = '__ada'
            script.async = true
            script.setAttribute('strategy', 'afterInteractive')
            script.setAttribute('data-handle', adaDataHandle)

            document.body.append(script)

            window.adaSettings = {
                handle: adaDataHandle,
                eventCallbacks: {
                    chat_lead: function () {
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({ event: 'chatLead' })
                    },
                },
            }

            // if ada entry is present, show it
            if (adaEntry) {
                adaEntry.style.display = 'block'
            }

            console.log('chat enabled')
        }

        // trigger or cancel ada campaigns
        handleAdaCampaign(path)
    }, [adaDataHandle, path])

    useEffect(() => {
        // Make sure the riskified cookie is set
        // This is passed into the beacon and then needed again during checkout
        if (!cookies.RISKIFIED_SESSION_ID) {
            setCookie(RISKIFIED_SESSION_ID, crypto.randomUUID())
        }
    }, [])

    const onActive = (event: any) => {
        const newRiskifiedSid = crypto.randomUUID()
        // User came back from inactivity, create new riskified session id
        setCookie(RISKIFIED_SESSION_ID, newRiskifiedSid)
        if (window.RISKX) {
            window.RISKX.setSid(newRiskifiedSid)
        }
    }

    const { getLastIdleTime } = useIdleTimer({
        onActive,
        timeout: 1000 * 60 * 30, // 30 minutes until considered inactive
        throttle: 500,
    })

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <link
                    rel="canonical"
                    href={`https://www.perfectgift.com${
                        canonicalRef || urlPath
                    }`}
                />
                <link rel="manifest" href="/manifest.json" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="/images/perfect_gift_logo_og.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="627" />
                {noIndexNoFollow && (
                    <meta name="robots" content="noindex,nofollow" />
                )}
            </Head>
            <React.Fragment>
                <Script src={'https://js.stripe.com/v3/'} async />
                {/*extole core script and createZone function*/}
                {process.env.NODE_ENV !== 'development' && (
                    <>
                        <Script
                            type="text/javascript"
                            src="https://perfectgift.extole.io/core.js"
                            async
                        />
                        <Script
                            id={'extole-create-zone'}
                            type="text/javascript"
                            dangerouslySetInnerHTML={{
                                __html: `
                    (function(c,e,k,l,a){c[e]=c[e]||{};for(c[e].q=c[e].q||[];a<l.length;)k(l[a++],c[e])})(window,"extole",function(c,e){e[c]=e[c]||function(){e.q.push([c,arguments])}},["createZone"],0);
                `,
                            }}
                        />{' '}
                    </>
                )}
                {enableDatadome && (
                    <>
                        {/*datadome bot protection */}
                        <Script strategy="lazyOnload" id="load-datadome">{`
                            window.ddjskey = '${process.env.NEXT_PUBLIC_DATADOME_CLIENT_KEY}'
                            window.ddoptions = {
                                endpoint: '${DATADOME_JS}',
                                sessionByHeader: true,
                                ajaxListenerPath: true
                            }
                        `}</Script>
                        <Script src={DATADOME_TAGS} strategy="lazyOnload" />
                    </>
                )}
                {!disableGoogleAnalytics && (
                    <>
                        {/* Global Site Tag (gtag.js) - Google Analytics */}
                        <Script
                            strategy="worker"
                            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                        />
                        <Script
                            id="gtag-init"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_TRACKING_ID}', {
                          page_path: window.location.pathname,
                        });
                      `,
                            }}
                        />
                        <Script
                            id="gtm-init"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                    function font_faml_uyoqvna(w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                        var f = d.getElementsByTagName(s)[0], j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : '';
                        // j.async = true;
                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    }
                    font_faml_uyoqvna(window, document, 'script', 'dataLayer', "${process.env.NEXT_PUBLIC_GTM_ID}");
            `,
                            }}
                        />

                        <Script
                            id="riskified-beacon"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
                                (function() {
                                    function riskifiedBeaconLoad() {
                                        var store_domain = 'perfectgift.com';
                                        var session_id = '${cookies[RISKIFIED_SESSION_ID]}';
                                        var url = ('https:' == document.location.protocol ? 'https://' : 'http://')
                                        + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
                                        var s = document.createElement('script');
                                        s.type = 'text/javascript';
                                        s.async = true;
                                        s.src = url;
                                        var x = document.getElementsByTagName('script')[0];
                                        x.parentNode.insertBefore(s, x);
                                    }
                                    if (window.attachEvent)
                                        window.attachEvent('onload', riskifiedBeaconLoad)
                                    else
                                        window.addEventListener('load', riskifiedBeaconLoad, false);
                                    })();
                                `,
                            }}
                        />
                    </>
                )}
            </React.Fragment>
            <AuthProvider>
                <CookiesProvider>
                    <QueryClientProvider client={queryClient}>
                        {showPrivateLabelPartnerLayout ? (
                            <PrivateLabelPartnerLayout
                                Component={Component}
                                pageProps={pageProps}
                            />
                        ) : (
                            <GlobalLayout
                                Component={Component}
                                pageProps={pageProps}
                                isCorporate={isCorporate}
                            />
                        )}
                        <PhoneNumberReplacer />
                        <ReactQueryDevtools initialIsOpen={false} />
                        <SpeedInsights sampleRate={0.8} />
                    </QueryClientProvider>
                </CookiesProvider>
            </AuthProvider>
        </>
    )
}
