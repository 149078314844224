import {
    ReactNode,
    createContext,
    useContext,
    useMemo,
    useState
} from 'react'

type TCartPropertiesState = {
    cartTotalCards: number
    cartTotalLoad: number
    cartFlyoutOpen: boolean
    setCartTotalCards: Function
    setCartTotalLoad: Function
    setCartFlyoutOpen: Function,
    setOverLimitRequiresKYC: Function,
    overLimitRequiresKYC: boolean,
    showKYCForm: boolean,
    setShowKYCForm: Function
}

export const CartContext = createContext<TCartPropertiesState | null>(null)

export const useCartContext = (): TCartPropertiesState => {
    const context = useContext(CartContext)

    if (!context) {
        throw new Error('Please use CartContext in parent component')
    }

    return context
}

export const CartProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const [cartTotalCards, setCartTotalCards] = useState<number>(0)
    const [cartTotalLoad, setCartTotalLoad] = useState<number>(0)
    const [cartFlyoutOpen, setCartFlyoutOpen] = useState<boolean>(false)
    const [overLimitRequiresKYC, setOverLimitRequiresKYC] = useState<boolean>(false)
    const [showKYCForm, setShowKYCForm] = useState<boolean>(false)

    const values = useMemo(
        () => ({
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            overLimitRequiresKYC,
            showKYCForm,
            setCartTotalCards,
            setCartTotalLoad,
            setCartFlyoutOpen,
            setOverLimitRequiresKYC,
            setShowKYCForm

        }), [
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            overLimitRequiresKYC,
            showKYCForm
        ]
    )

    return (
        <CartContext.Provider value={values}>
            {children}
        </CartContext.Provider>
    )
}