import { EditPencil, RemoveButton } from '@/src/modules/cart/StyledComponents'
import { removeCartItem } from '@/src/modules/cart/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { ILineItem } from '@/src/modules/cart/ICart'
import determineEditLink from '@/src/modules/checkout/utilities/determineEditLink'
import { theme } from '@/src/stitches.config'

type TLineItemProps = {
    lineItemUuid: string
    mediumType: string
    brandMerchant: string
    cartLineItem: ILineItem
    removeItemFromCart: Function
    isPhysical: boolean
    isVirtual: boolean
    isCardToCard: boolean
    isPgPlus: boolean
    partner?: string
}

const LineItemRemoveAndEditButtons = ({
    removeItemFromCart,
    cartLineItem,
    lineItemUuid,
    isPhysical,
    isCardToCard,
    isVirtual,
    mediumType,
    brandMerchant,
    isPgPlus,
    partner,
}: TLineItemProps) => {
    const editLink: string = determineEditLink({
        isPhysical,
        isVirtual,
        isCardToCard,
        isPgPlus,
        partner: partner,
        brandSlug: brandMerchant,
        line_item_uuid: lineItemUuid,
    })

    return (
        <div className={'add-mt'}>
            <RemoveButton
                onClick={() =>
                    removeCartItem({
                        removeItemFromCart: removeItemFromCart,
                        line_item_uuid: lineItemUuid,
                        cartLineItem: cartLineItem,
                        mediumType: mediumType,
                    })
                }
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </RemoveButton>
            <EditPencil
                href={editLink}
                style={{ color: `${theme.colors.primary}` }}
            >
                <FontAwesomeIcon icon={faPencil} />
            </EditPencil>
        </div>
    )
}

export default LineItemRemoveAndEditButtons
