export const CORPORATE_BRANDS_PAGE: string = '/corporate/brands'
export const BRAND_PAGE: string = '/brands'
export const PG_PLUS_BUILD_PAGE: string = '/perfectgift-plus/build'
export const CHECKOUT_PATH: string = '/checkout'
export const PG_PLUS_REDEMPTION_PATH: string = '/redeem-gift?token='
export const EMAIL_DELIVERY: string = 'email'
export const TEXT_DELIVERY: string = 'text'
export const PHYSICAL_DELIVERY: string = 'physical'
export const PHONE_REG_EXP: RegExp = /^\(\d{3}\)\s\d{3}-\d{4}$/
export const PHONE_REG_WITH_AREA_ONLY_NUMBERS: RegExp = /^\+\d+\d{3}\d{3}\d{4}$/
export const MIN_PG_PLUS_CARD_LOAD: number = 5
export const MAX_PG_PLUS_SINGLE_CARD_LOAD: number = 100

export const CARD_LINK: string = 'card-link'
export const BRANDED_EGIFT: string = 'branded-egift'
export const UNBRANDED_TO_LINK: string = 'unbranded-to-branded'
export const UNBRANDED_TO_EGIFT: string = 'unbranded-to-egift'
export const CHANGE_BRAND: string = 'change-brand'
export const PLASTIC_REDEMPTION: string = 'plastic'
export const UNENROLL_CARD_FROM_LINK: string = 'unenroll-card'
export const EDIT_SAVED_CARDS: string = 'edit-saved-cards'

// remove /test from this AND move page out of test directory
export const REFER_A_FRIEND_PAGE: string = '/test/refer-a-friend'

export const PARTNER_BRAND_PAGE: string = '/partner/brands'
export const PARTNER_CHECKOUT_PAGE: string = '/partner/checkout'
export const PARTNER_CONFIRMATION_PAGE: string =
    '/partner/checkout/confirmation'
export const PARTNER_LOGIN_PAGE: string = '/partner/login'
export const PARTNER_ORDER_STATUS_PAGE: string = '/partner/order-status'
