import { styled } from '@/src/stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavigationMenuDropdownItem from './Navigation/NavigationMenuDropdownItem'
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { signOut } from '@/src/modules/login/FirebaseAuth'
import { LOGIN_PAGE } from '@/src/modules/account/received/constants'
import {
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuRoot,
} from '@/src/common/components/elements/Navigation/StyledComponents'
import { LabelMenu } from '@/src/common/components/elements/Menus/StyledComponents'
import {
    PARTNER_BRAND_PAGE,
    PARTNER_LOGIN_PAGE,
} from '@/src/modules/pg-plus/constants'

const UserIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    fontSize: 15,
    ml: 0,
    mr: 8,
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

const NavigationLoginMenu = styled('div', {
    display: 'flex',
    flexDirection: 'column',
})

const NavigationCorporateMenuItems = styled('div', {
    '&.corporateMenu': {
        order: 1,
        marginBottom: 15,
    },
    '&.consumerMenu': {
        order: 2,
    },
})

const NavigationConsumerMenuItems = styled('div', {
    '&.corporateMenu': {
        order: 2,
    },
    '&.consumerMenu': {
        order: 1,
        marginBottom: 15,
    },
})

const LoginVsAccountText = styled('span', {
    textAlign: 'left',
    width: 105,
})

type THeaderProps = {
    isCorporate: boolean
    partner?: string
}

const HeaderUserMenu = ({ isCorporate, partner }: THeaderProps) => {
    const { user } = useUser()
    const greeting = user ? 'MY ACCOUNT' : 'LOG IN'

    const partnerLoginPage: string = `${PARTNER_LOGIN_PAGE}?type=partner/brands`
    const partnerBrandPage: string = PARTNER_BRAND_PAGE

    const loginUrl = partner ? partnerLoginPage : LOGIN_PAGE
    const signOutUrl = partner ? partnerBrandPage : '/'

    // if user is in partner portal, show limited header options
    if (partner) {
        return (
            <NavigationMenuRoot aria-label={'User'} style={{ marginRight: 7 }}>
                <NavigationMenuList>
                    <NavigationMenuItem
                        onPointerMove={(event) => event.preventDefault()}
                        onPointerLeave={(event) => event.preventDefault()}
                        className={'hasChildren'}
                        data-testid={'consumer-login-menu'}
                    >
                        <LabelMenu>
                            <UserIcon icon={faUser} />
                            <LoginVsAccountText>{greeting}</LoginVsAccountText>
                        </LabelMenu>
                        <NavigationMenuContent
                            data-testid={'dropdown-content-wrapper'}
                        >
                            <NavigationLoginMenu>
                                <NavigationConsumerMenuItems
                                    className={'consumerMenu'}
                                    style={{ marginBottom: 0 }}
                                >
                                    <NavigationMenuDropdownItem
                                        label={'FOR INDIVIDUALS'}
                                        test_id="dt-consumer-section-header"
                                    />
                                    {user ? (
                                        <NavigationMenuDropdownItem
                                            label={'Sign Out'}
                                            icon_class={'fa-right-to-bracket'}
                                            test_id="dt-user-logout"
                                            is_external_url={false}
                                            url={signOutUrl}
                                            onClick={signOut}
                                        />
                                    ) : (
                                        <NavigationMenuDropdownItem
                                            label={'Log in to PerfectGift'}
                                            icon_class={'fa-gift'}
                                            url={loginUrl}
                                            test_id="dt-login"
                                        />
                                    )}
                                </NavigationConsumerMenuItems>
                            </NavigationLoginMenu>
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenuRoot>
        )
    }

    return (
        <NavigationMenuRoot aria-label={'User'} style={{ marginRight: 7 }}>
            <NavigationMenuList>
                <NavigationMenuItem
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    className={'hasChildren'}
                    data-testid={'consumer-login-menu'}
                >
                    <LabelMenu>
                        <UserIcon icon={faUser} />
                        <LoginVsAccountText>{greeting}</LoginVsAccountText>
                    </LabelMenu>
                    <NavigationMenuContent
                        data-testid={'dropdown-content-wrapper'}
                        className={isCorporate ? 'lastMenu' : ''}
                    >
                        <NavigationLoginMenu>
                            <NavigationConsumerMenuItems
                                className={
                                    isCorporate
                                        ? 'corporateMenu'
                                        : 'consumerMenu'
                                }
                            >
                                <NavigationMenuDropdownItem
                                    label={'FOR INDIVIDUALS'}
                                    test_id="dt-consumer-section-header"
                                />
                                {user ? (
                                    <>
                                        <NavigationMenuDropdownItem
                                            label={'Dashboard'}
                                            icon_class={'fa-home'}
                                            url={'/account'}
                                            test_id="dt-corp"
                                            is_external_url={false}
                                        />
                                        <NavigationMenuDropdownItem
                                            label={'Received Gifts'}
                                            icon_class={'fa-gift'}
                                            url={'/account/gifts/received'}
                                            test_id="dt-corp"
                                            is_external_url={false}
                                        />
                                        <NavigationMenuDropdownItem
                                            label={'Manage Account'}
                                            icon_class={'fa-user'}
                                            url={'/account/manage-account'}
                                            test_id="dt-corp"
                                            is_external_url={false}
                                        />
                                        <div onClick={signOut}>
                                            <NavigationMenuDropdownItem
                                                label={'Sign Out'}
                                                icon_class={
                                                    'fa-right-to-bracket'
                                                }
                                                test_id="dt-user-logout"
                                                is_external_url={false}
                                                url={signOutUrl}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <NavigationMenuDropdownItem
                                        label={'Log in to PerfectGift'}
                                        icon_class={'fa-gift'}
                                        url={loginUrl}
                                        test_id="dt-login"
                                    />
                                )}
                            </NavigationConsumerMenuItems>
                            <NavigationCorporateMenuItems
                                className={
                                    isCorporate
                                        ? 'corporateMenu'
                                        : 'consumerMenu'
                                }
                            >
                                <NavigationMenuDropdownItem
                                    label={'FOR BUSINESS'}
                                    test_id="dt-corp-section-header"
                                />
                                <NavigationMenuDropdownItem
                                    label={'Log in to Corporate Portal'}
                                    icon_class={'fa-briefcase'}
                                    url={'https://corp.perfectgift.com/login'}
                                    test_id="dt-corp"
                                    is_external_url={true}
                                />
                                <NavigationMenuDropdownItem
                                    label={'Request a corporate account'}
                                    icon_class={'fa-circle-plus'}
                                    url={'/corporate/bulk'}
                                    test_id="dt-create-corporate"
                                />
                            </NavigationCorporateMenuItems>
                        </NavigationLoginMenu>
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default HeaderUserMenu
