import { TPartnerPayload } from '@/src/modules/partners/types'

function determineValueEarnedForPartner(
    partnerData: TPartnerPayload,
    denomination: string,
    shortened: boolean
): string {
    if (!partnerData) {
        return ''
    }

    if (!denomination) {
        return `Earn ${partnerData?.loyaltyConversionRate} ${partnerData?.loyaltyUnit} per dollar spent`
    }

    const earnedValue: number =
        Number(denomination) * (partnerData?.loyaltyConversionRate ?? 0)

    if (shortened) {
        return `Earns ${earnedValue} ${partnerData?.loyaltyUnit} after purchase`
    }

    return `You will earn ${earnedValue} ${partnerData?.loyaltyUnit} from this purchase`
}

export default determineValueEarnedForPartner
