import { keyframes } from '@/src/stitches.config'

export const slideDown = keyframes({
    '0%': { transform: 'translateY(-5%)', opacity: 0 },
    '100%': { transform: 'translateY(0%)', opacity: 1 },
})

export const gradientAnimation = keyframes({
    '0%': {
        backgroundPosition: '0% 100%',
    },
    '50%': {
        backgroundPosition: '70% 70%',
    },
    '100%': {
        backgroundPosition: '0% 100%',
    },
})

export const beat = keyframes({
    '0%': { transform: 'scale(1, 1)', opacity: 0 },
    '50%': { transform: 'scale(1.25, 1.25)', opacity: 0.5 },
    '100%': { transform: 'scale(1, 1)', opacity: 1 },
})

export const fadeInFast = keyframes({
    '0%': { opacity: 0 },
    '10%': { opacity: 0.7 },
    '20%': { opacity: 1 },
})
