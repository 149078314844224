import { useQuery } from 'react-query'
import getCart from '../queries/GetCartQuery'
import { useCookies } from 'react-cookie'
import { PG_CART_SESSION_UUID, PG_CART_STATE_UUID } from '@/config/cookieNames'
import get from 'lodash/get'

const useGetCartManually = () => {
    const [cookies, setCookie] = useCookies([PG_CART_SESSION_UUID])
    const cartSessionUuid = get(cookies, PG_CART_SESSION_UUID)
    const queryKey = ['cartSessionUuid', cartSessionUuid]
    const { refetch, data, error, isLoading, isSuccess, isError } = useQuery(
        queryKey,
        getCart,
        {
            onSuccess: (cartResponse: {
                cart_session_uuid: string
                cart_state_uuid: string
            }) => {
                // cartResponse could be empty if no cart exists
                if (cartResponse?.cart_session_uuid) {
                    setCookie(
                        PG_CART_SESSION_UUID,
                        cartResponse.cart_session_uuid,
                        { path: '/' }
                    )
                    setCookie(
                        PG_CART_STATE_UUID,
                        cartResponse.cart_state_uuid,
                        {
                            path: '/',
                        }
                    )
                }
            },
            onError: (error: any) => {
                // error.name = ERROR_GETTING_CART
                // Sentry.captureException(error, {
                //     contexts: {
                //         data: {
                //             errorMessage: "Error posting activation form",
                //         },
                //     },
                //     user: {
                //         id: JSON.stringify(Cookies.get('GCG_CLIENT_USER_ID')),
                //     },
                // })
            },
            // @ts-ignore
            refetchOnWindowFocus: false,
            cacheTime: 0, // Cache disable, since this is a manually-triggered
            enabled: false, // This prevents the query from fetching automatically
        }
    )

    return { refetch, data, error, isLoading, isSuccess, isError }
}

export default useGetCartManually
