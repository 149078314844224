export const NETWORK_SLUGS = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
}

export const ACTIVATION_SOURCE_WEBSITE_ACTIVATE = 'website_activate'
export const ACTIVATION_SOURCE_WEBSITE_REDEEM = 'website_redeem'
export const ACTIVATION_SOURCE_IVR = 'ivr'
export const ACTIVATION_SOURCE_CSP = 'csp'

export const BUILDER_LEAD_FORM_DM_NAME = 'lead-form-content'

export const UTM_SOURCE: string = 'utm_source'
export const UTM_MEDIUM: string = 'utm_medium'
export const UTM_CAMPAIGN: string = 'utm_campaign'
export const UTM_ID: string = 'utm_id'

export const PARTNER_DATA_COOKIE: string = 'PARTNER_DATA'

export const CORPORATE_ORDER_NUMBER_REGEX = /^\d+-\d{4}-\d{4}-\d{4}$/

export const CUSTOM_CARD_IMAGE = 'Custom'

export enum ParsedIMBStatus {
    SENT_TO_FULFILLMENT_CENTER = 'sent_to_fulfillment_center',
    OUT_FOR_DELIVERY = 'out_for_delivery',
    MOVING_THROUGH_USPS_SYSTEM = 'moving_through_usps_system',
    DELIVERED = 'delivered',
}

export const BUILDER_IO_VERSION = 'v3'

export const APP_TYPE = 'app_type'
export const IOS_PWA_APP_TYPE = 'ios_pwa'
export const NOT_IOS_PWA_APP_TYPE = 'not_ios_pwa_app'

export const PHONE_TOO_MANY_ATTEMPTS: string = 'phone_verify_too_many_attempts'
export const CHECKOUT_FRAUD_BLOCK: string = 'checkout_fraud_block'
export const CHECKOUT_PAYMENT_DECLINED: string = 'checkout_payment_declined'
export const PHONE_DENIED: string = 'phone_denied'
