import React from 'react'
import Drawer from 'react-modern-drawer'
import { styled } from '@/src/stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-light-svg-icons'
import CartItem from '@/src/common/components/elements/Layout/CartItem'
import { ICart } from '@/src/modules/cart/ICart'
import Image from 'next/legacy/image'
import Text from '@/src/common/components/elements/Text/Text'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import PurchaseFlowMaxAlert from '@/src/common/components/elements/Alerts/PurchaseFlowMaxAlert'
import { MAX_CARDS, MAX_PURCHASE_AMOUNT } from '@/src/modules/checkout/strings'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import CartBoxItem from '@/src/common/components/elements/Layout/CartBoxItem'
import { LoadingOverlay } from '@/src/modules/checkout/forms/StyledComponents'
import { ClipLoader } from 'react-spinners'
import CartFlyoutCheckout from '@/src/common/components/elements/Layout/CartFlyoutCheckout'
import { PARTNER_BRAND_PAGE } from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'

const CartFlyoutDrawer = styled(Drawer, {
    width: '100% !important',
    maxWidth: '500px',
    bc: '$lightest',
    display: 'flex',
    fd: 'column',
    variants: {
        emptyCart: {
            true: {
                justifyContent: 'flex-start',
            },
            false: {
                justifyContent: 'space-between',
            },
        },
    },
})

const Container = styled('div', {
    display: 'flex',
    fd: 'column',
    jc: 'space-between',
    height: '95%',
    width: '100%',
    margin: '0 auto auto',
})

const CartHeadingContainer = styled('div', {
    display: 'flex',
    ai: 'center',
    p: '0 1rem',
    color: '$darkest',
    textTransform: 'uppercase',
    variants: {
        emptyCart: {
            true: {
                justifyContent: 'flex-end',
            },
            false: {
                justifyContent: 'space-between',
            },
        },
    },
})

const CloseWrapper = styled('button', {
    margin: '10px 0px 10px 10px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: 'inherit',
    border: 'none',
    fontSize: 18,
})

const CartItemsContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    flex: 1,
    oy: 'auto',
})

const EmptyCartContainer = styled('div', {
    display: 'flex',
    fd: 'column',
    ai: 'center',
    p: '1rem',
})

const EmptyCartHeadingText = styled(Text, {
    fontSize: '$7',
    fontWeight: 'bold',
    color: '$darkest',
    m: '3rem auto 1rem',
    '@sm': {
        m: '5rem auto 1rem',
    },
})

const EmptyCartSubheadingText = styled(Text, {
    fontSize: '$5',
    color: '$dark',
    m: '24px 10px',
    ta: 'center',
    '@sm': {
        m: '24px 48px',
    },
})

const EmptyCartButtonContainer = styled('div', {
    m: '1.5rem auto 1rem',
    '& a': {
        fontSize: '$4',
        '@sm': {
            fontSize: '$6',
        },
    },
})

interface CartFlyoutProps {
    isOpen: boolean
    closeFlyout: Function
    removeItemFromCart: Function
    showLoader: boolean
    cart?: ICart | null
    partnerData?: TPartnerPayload
}

const CartFlyout = ({
    cart,
    isOpen,
    closeFlyout,
    showLoader,
    removeItemFromCart,
    partnerData,
}: CartFlyoutProps) => {
    const lineItems = getLineItemsForCart(cart)
    const { lineItemsInBoxes, lineItemsNotInBox } = lineItems

    const continueShoppingUrl: string = partnerData
        ? PARTNER_BRAND_PAGE
        : '/visa-gift-cards'

    const cartIsEmpty = lineItemsInBoxes.length + lineItemsNotInBox.length === 0
    const requiresKYC: boolean =
        Number(cart?.resolved_data?.load_value_total ?? 0) >
            MAX_PURCHASE_AMOUNT ||
        Number(cart?.resolved_data?.items_count ?? 0) > MAX_CARDS

    return (
        <CartFlyoutDrawer
            open={isOpen}
            onClose={() => {
                closeFlyout()
            }}
            direction={'right'}
            emptyCart={cartIsEmpty}
            customIdSuffix={'_pgc_cart_flyout_drawer'}
        >
            {showLoader && (
                <LoadingOverlay>
                    <ClipLoader color={'black'} loading={true} size={30} />
                </LoadingOverlay>
            )}
            <CartHeadingContainer emptyCart={cartIsEmpty}>
                {cartIsEmpty ? '' : 'Cart'}

                <CloseWrapper
                    role={'button'}
                    onClick={() => closeFlyout()}
                    tabIndex={0}
                >
                    <FontAwesomeIcon icon={faClose} size={'lg'} />
                </CloseWrapper>
            </CartHeadingContainer>
            {cart && !cartIsEmpty ? (
                <Container>
                    <CartItemsContainer style={{ margin: 10 }}>
                        {requiresKYC && <PurchaseFlowMaxAlert />}
                        {lineItemsInBoxes?.map((box) => {
                            return (
                                <CartBoxItem
                                    key={`item-${box.box_uuid}`}
                                    box={box}
                                    removeItemFromCart={removeItemFromCart}
                                />
                            )
                        })}
                        {lineItemsNotInBox?.map((item, index) => {
                            const isLastItem: boolean =
                                index === lineItemsNotInBox?.length - 1

                            return (
                                <CartItem
                                    key={item.line_item_uuid}
                                    cartLineItem={item}
                                    removeItemFromCart={removeItemFromCart}
                                    partnerData={partnerData}
                                    hideBottomBorder={isLastItem}
                                />
                            )
                        })}
                    </CartItemsContainer>
                    <CartFlyoutCheckout
                        closeFlyout={closeFlyout}
                        orderTotal={cart.resolved_data.order_total}
                        partnerData={partnerData}
                        continueShoppingUrl={continueShoppingUrl}
                    />
                </Container>
            ) : (
                <EmptyCartContainer>
                    <Image
                        src={'/images/empty_cart_image.png'}
                        alt={'Empty Cart'}
                        width={442}
                        height={326}
                        layout={'intrinsic'}
                        priority={false}
                        blurDataURL={'/images/empty_cart_image.png'}
                    />
                    <EmptyCartHeadingText>
                        Your cart is empty.
                    </EmptyCartHeadingText>
                    <EmptyCartSubheadingText>
                        {
                            "Looks like you haven't added anything to your cart yet."
                        }
                    </EmptyCartSubheadingText>
                    <EmptyCartButtonContainer
                        onClick={() => {
                            closeFlyout()
                        }}
                    >
                        <LinkButton
                            variant={'primary'}
                            size={'lg'}
                            textTransform={'uppercase'}
                            label={'Find the perfect gift'}
                            url={continueShoppingUrl}
                        />
                    </EmptyCartButtonContainer>
                </EmptyCartContainer>
            )}
        </CartFlyoutDrawer>
    )
}

export default CartFlyout
