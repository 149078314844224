import MobileNavDrawer from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavDrawer'
import LogoLink from '@/src/common/components/elements/Layout/LogoLink'
import { HeaderTopContentLeft } from '@/src/common/components/elements/Navigation/header/StyledComponents'

type TLogoNavProps = {
    isCorporate: boolean
    partner?: string
    showMobileNav?: boolean
    imageMinWidth?: string | number
}

const HeaderLogoAndMobileNav = ({
    partner,
    isCorporate,
    showMobileNav,
}: TLogoNavProps) => {
    return (
        <HeaderTopContentLeft>
            {showMobileNav && (
                <MobileNavDrawer partner={partner} isCorporate={isCorporate} />
            )}
            <LogoLink isCorporate={isCorporate} partner={partner} />
        </HeaderTopContentLeft>
    )
}

export default HeaderLogoAndMobileNav
