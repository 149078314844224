import { useMutation } from 'react-query'
import postAddCart from '../queries/PostAddCartQuery'
import { PG_CART_SESSION_UUID, PG_CART_STATE_UUID } from '@/config/cookieNames'
import { useCookies } from 'react-cookie'

const usePostAddCart = () => {
    const [cookies, setCookie] = useCookies([PG_CART_SESSION_UUID])
    const { mutate, data, error, isLoading, isSuccess, isError } = useMutation(
        'postAddCart',
        postAddCart,
        {
            onSuccess: (cartResponse: {
                cart_session_uuid: string
                cart_state_uuid: string
            }) => {
                setCookie(
                    PG_CART_SESSION_UUID,
                    cartResponse.cart_session_uuid,
                    { path: '/' }
                )
                setCookie(PG_CART_STATE_UUID, cartResponse.cart_state_uuid, {
                    path: '/',
                })
            },
            onError: () => {},
            // @ts-ignore
            refetchOnWindowFocus: false,
            retry: false,
        }
    )

    return { mutate, data, error, isLoading, isSuccess, isError }
}

export default usePostAddCart
