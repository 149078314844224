import { ColorfulContainer } from '@/src/common/components/elements/Layout/Banners/StyledComponents'
import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import { DEFAULT_PARTNER_BANNER_TEXT } from '@/src/modules/partners/constants'
import Link from 'next/link'
import { PARTNER_BRAND_PAGE } from '@/src/modules/pg-plus/constants'

type TPartnerBannerProps = {
    partner: string
    customText?: string
}

const Content = styled('div', {
    width: '93vw',
    display: 'flex',
    ai: 'center',
    jc: 'center',
    color: '$white',
    padding: 8,
    fontSize: '1.1rem',
    flexWrap: 'wrap',
    gap: 10,
})

const StyledImage = styled(Image, {
    width: 'auto',
    height: 22,
    display: 'block',
    marginY: 'auto',
})

function determineLogoAndText(partner: string) {
    switch (partner) {
        case 'pnc':
            return {
                partnerLogo: '/images/PNC-logo-white.svg',
                textAfterLogo: DEFAULT_PARTNER_BANNER_TEXT,
            }
        case 'bank-of-america':
            return {
                partnerLogo: '/images/bank-of-america-logo.svg',
                textAfterLogo: DEFAULT_PARTNER_BANNER_TEXT,
            }
        default:
            return {
                partnerLogo: '',
                textAfterLogo: DEFAULT_PARTNER_BANNER_TEXT,
            }
    }
}

const PartnerBanner = ({ partner, customText }: TPartnerBannerProps) => {
    const { partnerLogo, textAfterLogo } = determineLogoAndText(partner)

    return (
        <Link type={'button'} href={PARTNER_BRAND_PAGE}>
            <ColorfulContainer style={{ cursor: 'default' }}>
                <Content>
                    {partnerLogo && (
                        <StyledImage
                            src={partnerLogo}
                            alt={`${partner} logo`}
                            width={150}
                            height={50}
                            priority={true}
                        />
                    )}
                    {customText ?? textAfterLogo}
                </Content>
            </ColorfulContainer>
        </Link>
    )
}

export default PartnerBanner
