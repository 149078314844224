import { FC } from 'react'
import { styled } from '@/src/stitches.config'
import HeaderNetworkImages from '@/src/common/components/elements/Layout/HeaderNetworkImages'
import HeaderUserMenu from '@/src/common/components/elements/Layout/HeaderUserMenu'
import LogoLink from '@/src/common/components/elements/Layout/LogoLink'
import MobileNavDrawer from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavDrawer'
import NavigationMenuWithHover from '@/src/common/components/elements/Layout/Navigation/NavigationMenuWithHover'
import desktopNavCorporateData from '@/public/desktopNavMenuCorporateData.json'
import HeaderCtaButtons from './HeaderCtaButtons'

const HeaderWrapper = styled('header', {
    bc: '$white',
    color: '$dark',
    fontSize: '3',
    position: 'relative',
    zIndex: 2,
    '@md': {
        p: '0',
    },
})
const HeaderTop = styled('div', {
    display: 'flex',
    fd: 'row',
    width: '1440px',
    maxWidth: '98%',
    margin: 'auto',
    '@md': {
        height: 95,
        jc: 'space-between',
        maxWidth: '93%',
    },
})
const HeaderTopContentLeft = styled('div', {
    display: 'flex',
    ai: 'center',
    '@sm': {
        jc: 'space-between',
    },
})
const HeaderTopContentRight = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
        jc: 'right',
    },
})
const HeaderBottom = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
        position: 'relative',
        width: '100%',
    },
})

const CorporateHeader: FC = () => {
    const desktopNavItems = desktopNavCorporateData

    return (
        <>
            <HeaderWrapper>
                <HeaderTop>
                    <HeaderTopContentLeft>
                        <MobileNavDrawer isCorporate={true} />
                        <LogoLink isCorporate={true} />
                    </HeaderTopContentLeft>
                    <HeaderTopContentRight>
                        <HeaderNetworkImages />
                        <HeaderUserMenu isCorporate={true} />
                        <HeaderCtaButtons isCorporate={true} />
                    </HeaderTopContentRight>
                </HeaderTop>
                <HeaderBottom>
                    <NavigationMenuWithHover menuData={desktopNavItems} />
                </HeaderBottom>
            </HeaderWrapper>
            {/*<ToastSampleCard />*/}
        </>
    )
}

export default CorporateHeader
