import { FC } from 'react'
import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import NextLink from 'next/link'

const HeaderWrapper = styled('header', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@sm': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
})

const NavigationMenuItemDivider = styled('div', {
    display: 'none',
    my: '6px',
    mx: '61px',
    borderLeft: '1px solid $darkest',
    height: '23px',
    '@sm': {
        display: 'block',
    },
})

export interface IPrivateLabelPartnerHeader {
    partnerLogo: string
}

const PrivateLabelPartnerHeader: FC<IPrivateLabelPartnerHeader> = ({
                                                                       partnerLogo,
                                                                   }) => {
    return (
        <>
            <HeaderWrapper>
                <NextLink href={`/`} passHref
                          style={{ position: 'relative', width: '162px', height: '128px' }}>
                    <Image
                        src={partnerLogo}
                        alt={`Logo`}
                        fill
                        sizes='100vw'
                        style={{
                            objectFit: 'contain',
                        }}
                    />
                </NextLink>
                <NavigationMenuItemDivider />
                <NextLink href={'/activate'}>Activate card</NextLink>
                <NavigationMenuItemDivider />
                <NextLink href={'/check-balance'}>Check balance</NextLink>
            </HeaderWrapper>
        </>
    )
}

export default PrivateLabelPartnerHeader
