import {
    BRAND_PAGE,
    PARTNER_BRAND_PAGE,
    PG_PLUS_BUILD_PAGE,
} from '@/src/modules/pg-plus/constants'

type TEditCartProps = {
    isPhysical: boolean
    isVirtual: boolean
    isCardToCard: boolean
    brandSlug: string
    line_item_uuid: string
    isPgPlus: boolean
    partner?: string
}

export default function determineEditLink({
    isCardToCard,
    isPhysical,
    isVirtual,
    brandSlug,
    line_item_uuid,
    isPgPlus,
    partner,
}: TEditCartProps) {
    let query: string = `?item_edit=${line_item_uuid}`

    // @TODO handle pg plus? will need to build page for partner
    if (partner) {
        return `${PARTNER_BRAND_PAGE}/${brandSlug}${query}`
    }

    if (isPhysical) {
        return `/personalized-visa-gift-card${query}&step=2`
    }

    if (isVirtual) {
        return `/virtual-visa-gift-cards${query}`
    }

    if (isCardToCard) {
        return isPgPlus
            ? `${PG_PLUS_BUILD_PAGE}${query}`
            : `${BRAND_PAGE}/${brandSlug}${query}`
    }

    return '/checkout'
}
